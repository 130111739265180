<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <VideoPlayer
      :youtubeSRC="youtubeSRC"
      :isOpenModalVid="$store.state.modalState2"
      :closeModalVid="closeModalVid"
    />
    <Popover
      class="fixed left-0 right-0 top-0 z-50 bg-white transition-all duration-300 ease-in-out lg:left-0 lg:right-0"
    >
      <div class="fixed left-0 right-0 top-0 z-[999] hidden h-1 bg-bluegray-100"></div>
      <div
        v-if="$store.state.warn"
        class="bg-[#164194] pb-3 pt-3 opacity-100 md:h-10 md:pb-0 md:pt-0"
      >
        <div
          class="relative mx-auto flex h-full max-w-6xl items-center justify-between px-4 pr-0 text-left leading-tight sm:px-3"
        >
          <span class="flex items-center justify-start">
            <span
              class="mr-2 rounded bg-[#00aca9] px-1 py-0 pt-0.5 text-xs font-medium text-white"
              >Appel à candidatures</span
            >
            <span class="max-w-[180px] truncate text-xs text-white sm:max-w-lg">
              <router-link :active-class="'text-white'" to="/events/f9a8a66baa3b">
                <span class="font-medium uppercase"></span> : Incubateur culturel de
                l'Institut français de Bukavu - LA PÉPINIÈRE
              </router-link>
            </span>
          </span>
          <span
            @click="closeWaring()"
            class="absolute right-0 top-1/2 -mt-2 mr-4 cursor-pointer text-white sm:mr-3"
          >
            <svg
              class="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
        </div>
      </div>
      <div
        class="navBlock mx-auto max-w-6xl px-4 pt-2 transition-all duration-300 ease-in-out sm:px-3"
      >
        <div
          class="pt-4.5 relative flex items-center justify-between border-b border-gray-300 py-6 xl:justify-start xl:space-x-10"
        >
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <router-link to="/#">
              <span class="sr-only">Institut français de Bukavu</span>
              <img
                class="absolute -left-2 -top-[12px] z-50 h-[92px] w-auto"
                src="/img/logo/logo_1.png"
                alt=""
              />
            </router-link>
          </div>
          <div class="-my-2 mr-1 flex justify-self-center xl:hidden">
            <div class="my-auto mr-5 flex justify-end">
              <TranslateC />
            </div>
            <PopoverButton
              class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 outline-none ring-2 ring-inset ring-gray-200 hover:bg-bluegray-50 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-300"
            >
              <span class="sr-only mr-2 pt-[0.8px] text-sm font-medium">Menu</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden space-x-6 xl:flex">
            <Popover class="relative -ml-[20px] -mt-[2.5px]" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-blue active-link' : 'text-gray-600',
                  ' group bg-white rounded-md inline-flex group items-center text-base font-medium hover: ',
                ]"
              >
                <span class="focus: font-medium">{{ $t("menu_t1") }}</span>
                <ChevronDownIcon
                  :class="[
                    open
                      ? 'text-gray-600 active-link transform rotate-180'
                      : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  class="absolute z-50 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2"
                >
                  <div
                    class="z-50 overflow-hidden border border-gray-200 bg-white shadow-md"
                  >
                    <PopoverButton class="text-left">
                      <div class="relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8">
                        <img
                          class="absolute bottom-0 right-8 -mt-24 w-[30px] opacity-[0.3] sm:-mt-20 lg:block"
                          src="/img/bars/5.png"
                        />
                        <router-link
                          v-for="item in ifbkMenu"
                          :key="item.name"
                          :to="item.href"
                          class="-m-3 flex items-start rounded-lg p-2 pl-3 hover:bg-bluegray-50 hover:text-blue"
                        >
                          <component
                            :is="item.icon"
                            class="h-6 w-6 flex-shrink-0 text-gray-500"
                            aria-hidden="true"
                          />
                          <div class="ml-4">
                            <span class="text-base font-medium">
                              {{ item.name }}
                            </span>
                            <p
                              v-html="item.description"
                              class="underline-none mt-2 text-sm text-gray-500"
                            ></p>
                          </div>
                        </router-link>
                      </div>
                    </PopoverButton>

                    <div
                      class="space-y-6 bg-bluegray-50 px-5 py-5 sm:flex sm:space-x-10 sm:space-y-0 sm:px-8"
                    >
                      <div class="flow-root" @click="openModalVid">
                        <a
                          class="ifb-link -m-3 flex cursor-pointer items-center rounded-md p-3 text-base font-medium"
                        >
                          <component
                            :is="callsToAction[0].icon"
                            class="h-6 w-6 flex-shrink-0 text-gray-500"
                            aria-hidden="true"
                          />
                          <span class="ml-3">{{ callsToAction[0].name }}</span>
                        </a>
                      </div>
                      <div class="flow-root">
                        <router-link
                          :to="callsToAction[1].href"
                          class="ifb-link -m-3 flex items-center rounded-md p-3 text-base font-medium"
                        >
                          <component
                            :is="callsToAction[1].icon"
                            class="h-6 w-6 flex-shrink-0 text-gray-500"
                            aria-hidden="true"
                          />
                          <span class="ml-3">{{ callsToAction[1].name }}</span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
            <Popover class="relative -mt-[2.5px]" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-blue active-link' : 'text-gray-600',
                  'group bg-white rounded-md inline-flex items-center text-base font-medium hover:',
                ]"
              >
                <span class="font-medium">{{ $t("menu_t2") }}</span>
                <ChevronDownIcon
                  :class="[
                    open
                      ? 'text-gray-600 active-link transform rotate-180'
                      : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  class="absolute left-1/2 z-50 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0"
                >
                  <div
                    class="dropd-menu relative z-50 overflow-hidden border border-gray-200 bg-white shadow-md"
                  >
                    <div class="relative grid gap-6 px-5 py-6 sm:gap-8 sm:p-8">
                      <img
                        class="absolute bottom-0 right-8 -mt-24 w-[30px] opacity-[0.3] sm:-mt-20 lg:block"
                        src="/img/bars/3.png"
                      />
                      <div class="justify flex">
                        <PopoverButton>
                          <router-link
                            :to="cultureMenu[1].href"
                            class="-m-3 flex items-start rounded-lg p-3 font-medium text-gray-600 hover:bg-gray-50"
                          >
                            <component
                              :is="cultureMenu[1].icon"
                              class="h-6 w-6 flex-shrink-0 text-gray-500"
                              aria-hidden="true"
                            >
                            </component>
                            <div class="ml-4">
                              <span class="font-medium">
                                {{ cultureMenu[1].name }}
                              </span>
                            </div>
                          </router-link>
                        </PopoverButton>
                      </div>
                    </div>
                    <div class="bg-bluegray-50 px-5 py-5 sm:px-8 sm:py-8">
                      <div>
                        <h3 class="text-xs font-medium uppercase text-gray-500">
                          {{ $t("menu_t13") }}
                        </h3>
                        <ul role="list" class="mt-4 space-y-4">
                          <li
                            v-for="post in progDataNavReversered"
                            :key="post.id"
                            class="w-full overflow-hidden truncate text-sm"
                          >
                            <PopoverButton
                              class="w-full overflow-hidden truncate text-left"
                            >
                              <router-link
                                :to="'/events/' + post.link.toString().slice(0, 12)"
                                class="hover:text-blackgray-700"
                              >
                                {{ post.name }}
                              </router-link>
                            </PopoverButton>
                          </li>
                        </ul>
                      </div>
                      <div class="mt-5 text-sm">
                        <PopoverButton class="w-full overflow-hidden truncate text-left">
                          <router-link to="/agenda#all" class="hover: font-medium">
                            {{ $t("menu_t14") }}
                            <span aria-hidden="true">&rarr;</span></router-link
                          >
                        </PopoverButton>
                      </div>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
            <router-link
              to="/mediatheque"
              class="active-campus text-base font-medium text-gray-600 hover:text-blue"
            >
              {{ $t("menu_t3") }}
            </router-link>
            <Popover class="relative -mt-[2.5px]" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-blue active-link' : 'text-gray-600',
                  'group bg-white rounded-md inline-flex items-center text-base font-medium hover:',
                ]"
              >
                <span class="font-medium">{{ $t("menu_t4") }}</span>
                <ChevronDownIcon
                  :class="[
                    open
                      ? 'text-gray-600 active-link transform rotate-180'
                      : 'text-gray-400',
                    'ml-2 h-5 w-5 group-hover:text-gray-500',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  class="absolute left-1/2 z-50 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0"
                >
                  <div
                    class="dropd-menu relative z-50 overflow-hidden border border-gray-200 bg-white shadow-md"
                  >
                    <div
                      class="relative grid gap-6 px-5 py-6 text-gray-600 sm:gap-8 sm:p-8"
                    >
                      <img
                        class="absolute -bottom-8 right-8 -mt-24 w-[30px] rotate-90 transform opacity-[0.3] sm:-mt-20 lg:block"
                        src="/img/bars/2.svg"
                      />
                      <div class="justify flex">
                        <PopoverButton>
                          <router-link
                            :key="item"
                            v-for="item in languesMenu"
                            :to="item.href"
                            class="-m-3 flex items-start rounded-lg p-3 font-medium hover:bg-gray-50"
                          >
                            <component
                              :is="item.icon"
                              class="h-6 w-6 flex-shrink-0 text-gray-500"
                              aria-hidden="true"
                            >
                            </component>
                            <div class="ml-4">
                              <span class="font-medium">
                                {{ item.name }}
                              </span>
                            </div>
                          </router-link>
                        </PopoverButton>
                      </div>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

            <router-link
              to="/campusfrance"
              class="active-campus text-base font-medium text-gray-500 hover:text-blue"
            >
              {{ $t("menu_t5") }}
            </router-link>

            <router-link
              to="/la-pepiniere"
              class="active-campus text-base font-medium text-gray-600 hover:text-blue"
            >
              {{ $t("menu_t0") }}
            </router-link>
            <div class="flex justify-end">
              <TranslateC />
            </div>
          </PopoverGroup>
          <!-- <div class="hidden items-center justify-end lg:hidden lg:w-0 xl:flex xl:flex-1">
            
             <router-link
              to="#espace"
              @click="openModalEspace"
              class="ifb-button ml-8 inline-flex items-center justify-center whitespace-nowrap border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blackgray-700"
            >
              Louer nos Espaces
            </router-link> 
          </div>-->
        </div>
      </div>

      <transition
        enter-active-class="duration-200 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="fixed inset-x-0 top-0 z-50 max-h-[100vh] min-h-[100vh] origin-top-right transform overflow-auto overflow-y-scroll bg-gray-900 bg-opacity-70 p-2 backdrop-blur-md backdrop-filter transition xl:hidden"
        >
          <div
            class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div class="px-5 pb-6 pt-5">
              <div class="flex items-center justify-between">
                <PopoverButton>
                  <router-link to="/">
                    <img
                      class="absolute top-5 z-50 -ml-1 h-20 w-auto xl:h-28"
                      src="/img/logo/logo_1.png"
                      alt=""
                    />
                  </router-link>
                </PopoverButton>

                <div class="-mr-0">
                  <PopoverButton
                    class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-500 outline-none ring-2 ring-inset ring-gray-200 hover:bg-bluegray-50 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-300"
                  >
                    <span class="sr-only">Fermer menu</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="mt-[40px]">
                <PopoverButton
                  v-for="item in ifbkMenu"
                  :key="item.name"
                  class="w-full text-left"
                >
                  <router-link
                    :to="item.href"
                    class="relative -ml-2 flex w-full items-start rounded-lg p-2 text-gray-600 hover:bg-gray-50"
                  >
                    <component
                      :is="item.icon"
                      class="h-6 w-6 flex-shrink-0 text-gray-500"
                      aria-hidden="true"
                    />
                    <div class="ml-4">
                      <p class="text-base font-medium">
                        {{ item.name }}
                      </p>
                    </div>
                    <ChevronDownIcon
                      class="absolute -right-2 ml-2 h-5 w-5 -rotate-90 text-gray-300"
                      aria-hidden="true"
                    />
                  </router-link>
                </PopoverButton>

                <div>
                  <router-link
                    v-for="item in cultureMenu"
                    :key="item.name"
                    :to="item.href"
                    class="relative -ml-2 flex w-full items-center rounded-md p-2 text-gray-600 hover:bg-gray-50"
                  >
                    <div class="flex justify-center">
                      <component
                        :is="item.icon"
                        class="h-6 w-6 flex-shrink-0 text-gray-500"
                        aria-hidden="true"
                      >
                      </component>
                      <div class="ml-4">
                        <p class="text-base font-medium">
                          {{ item.name }}
                        </p>
                        <!-- <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p> -->
                      </div>
                      <ChevronDownIcon
                        class="absolute -right-2 ml-2 h-5 w-5 -rotate-90 text-gray-300"
                        aria-hidden="true"
                      />
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="space-y-3">
              <PopoverButton class="w-full text-left">
                <div
                  class="space-y-6 bg-bluegray-50 py-5 pb-5 pl-5 pt-5 sm:flex sm:space-x-10 sm:space-y-0 sm:px-8"
                >
                  <span @click="openModalVid">
                    <a
                      class="-m-3 flex cursor-pointer items-center rounded-md p-3 text-base font-medium text-gray-600"
                    >
                      <component
                        :is="callsToAction[0].icon"
                        class="h-6 w-6 flex-shrink-0 text-gray-500"
                        aria-hidden="true"
                      />
                      <span class="ml-3">{{ callsToAction[0].name }}</span>
                    </a>
                  </span>
                  <span>
                    <router-link
                      :to="callsToAction[1].href"
                      class="mt-4 flex items-center rounded-md text-base font-medium text-gray-600 hover:bg-bluegray-50 sm:mt-0"
                    >
                      <component
                        :is="callsToAction[1].icon"
                        class="h-6 w-6 flex-shrink-0 text-gray-500"
                        aria-hidden="true"
                      />
                      <span class="ml-3">{{ callsToAction[1].name }}</span>
                    </router-link>
                  </span>
                </div>
                <div class="px-5 py-4">
                  <router-link
                    v-for="item in languesMenu"
                    :key="item.name"
                    :to="item.href"
                    class="relative -ml-2 flex w-full items-center rounded-md p-2 text-gray-600 hover:bg-gray-50"
                  >
                    <div class="flex justify-center">
                      <component
                        :is="item.icon"
                        class="h-6 w-6 flex-shrink-0 text-gray-500"
                        aria-hidden="true"
                      >
                      </component>
                      <div class="ml-4">
                        <p class="text-base font-medium">
                          {{ item.name }}
                        </p>
                        <!-- <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p> -->
                      </div>
                      <ChevronDownIcon
                        class="absolute -right-2 ml-2 h-5 w-5 -rotate-90 text-gray-300"
                        aria-hidden="true"
                      />
                    </div>
                  </router-link>

                  <router-link
                    v-for="item in campusMenu"
                    :key="item.name"
                    :to="item.href"
                    class="relative -ml-2 flex w-full items-center rounded-md p-2 hover:bg-gray-50"
                  >
                    <div class="flex justify-center">
                      <component
                        :is="item.icon"
                        class="h-6 w-6 flex-shrink-0 text-gray-500"
                        aria-hidden="true"
                      >
                      </component>
                      <div class="ml-4">
                        <p class="text-base font-medium">
                          {{ item.name }}
                        </p>
                        <!-- <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p> -->
                      </div>
                      <ChevronDownIcon
                        class="absolute -right-2 ml-2 h-5 w-5 -rotate-90 text-gray-300"
                        aria-hidden="true"
                      />
                    </div>
                  </router-link>
                </div>
                <div class="bg-bluegray-50 px-5 py-5">
                  <router-link
                    to="#espaces"
                    @click="openModalEspace"
                    class="ifb-button flex w-full items-center justify-center border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blackgray-700"
                  >
                    {{ $t("about_t37") }}
                  </router-link>
                </div>
              </PopoverButton>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
  <TransitionRoot appear :show="$store.state.modalState" as="template">
    <Dialog as="div" @close="closeModalEspace">
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-900 bg-opacity-70 backdrop-blur-md backdrop-filter"
            />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="my-8 inline-block w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h3" class="text-2xl font-bold leading-6">
                {{ $t("about_t37") }}
              </DialogTitle>
              <div class="mt-2">
                <p class="text-base text-gray-500">
                  {{ $t("about_t3700") }}

                  <span class="hidden">
                    La salle de spectacle, toute équipée, peut accueillir 120 personnes
                    assises. Le hall d'exposition est le lieu idéal pour vos cocktails et
                    autres événements plus informels.</span
                  >
                </p>
              </div>
              <!-- 
              <div class="my-10">
                <img src="/img/content/if_img13.jpg" alt="" />
              </div> -->
              <div class="mt-6 flex justify-start">
                <button
                  type="button"
                  class="hover:bg-blue-200 focus-visible:ring-blue-500 inline-flex justify-center border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                  @click="closeModalEspace"
                >
                  {{ $t("btn_t1") }}
                </button>
                <router-link
                  @click="closeModalEspace"
                  to="contact"
                  class="bg-blackgray ifb-button ml-4 flex justify-center px-4 py-2 font-medium text-white hover:text-white"
                >
                  <span>{{ $t("btn_t2") }}</span>
                  <svg viewBox="0 0 20 20" fill="currentColor" class="ml-2 mt-1 h-4">
                    <path
                      fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path></svg
                ></router-link>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
const youtubeSRC = "https://youtu.be/eLhrszucwXc";

const progData = ref();

import { ref, defineComponent, computed, inject } from "vue";

import {
  InformationCircleIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  XIcon,
  ViewGridIcon,
  CollectionIcon,
  GlobeAltIcon,
  AcademicCapIcon,
  IdentificationIcon,
} from "@heroicons/vue/outline";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import axios from "axios";

export default defineComponent({
  // eslint-disable-next-line
  name: "Navbar",
  components: {
    ChevronDownIcon,
    MenuIcon,
    XIcon,
  },

  setup() {
    var t = inject("txt");
    var store = inject("store", store);

    var ifbkMenu = [
      {
        name: t("menu_t6"),
        description: t("menu_t7"),
        href: "/about",
        icon: InformationCircleIcon,
      },
    ];
    const callsToAction = [
      { name: t("menu_t8"), icon: PlayIcon, id: "videoPlay" },
      {
        name: t("menu_t9"),
        href: "/contact",
        icon: PhoneIcon,
        id: "openContact",
      },
    ];
    const cultureMenu = [
      {
        name: t("menu_t3"),
        description: "...",
        href: "/mediatheque",
        icon: CollectionIcon,
      },
      {
        name: t("menu_t10"),
        description: "...",
        href: "/agenda#all",
        icon: ViewGridIcon,
      },
      {
        name: "La Pépinière",
        description: "...",
        href: "/la-pepiniere",
        icon: ViewGridIcon,
      },
    ];

    const languesMenu = [
      {
        name: t("menu_t11"),
        description: "...",
        href: "/langues",
        icon: GlobeAltIcon,
      },
      {
        name: t("menu_t12"),
        description: "...",
        href: "/certifications",
        icon: AcademicCapIcon,
      },
    ];

    const campusMenu = [
      {
        name: "Campus France",
        description: "...",
        href: "/campusfrance",
        icon: IdentificationIcon,
      },
    ];

    const categoriesMenu = ref({
      "L'Institut": [ifbkMenu],
      Culture: [cultureMenu],
      Langue: [languesMenu],
    });
    // console.log(categoriesMenu);

    const recentProgs = [
      {
        id: 1,
        name: "Concours nationnal d'éloquence",
        href: "/agenda",
      },
      {
        id: 2,
        name: "Invité d'Afrique - Alain Mabanckou",
        href: "/agenda",
      },
      {
        id: 3,
        name: "Rien ne vous oblige à faire de ma peine une chanson",
        href: "/agenda",
      },
    ];

    function closeWaring() {
      store.state.warn = false;
    }
    function closeModalEspace() {
      store.state.modalState = false;
    }
    function openModalEspace() {
      store.state.modalState = true;
    }
    async function openModalVid() {
      store.state.modalState2 = true;
      console.log(store.state.modalState2);
    }

    async function closeModalVid() {
      store.state.modalState2 = false;
    }

    axios
      .get(`${process.env.VUE_APP_API_ENDPOINT}/frontFiles/jsonFile/json_data.json?00004`)
      .then((r) => {
        progData.value = r.data;
      })
      .catch((err) => {
        console.log(err);
      });

    var progDataNavReversered = computed(() => {
      return progData.value.slice(0, 4);
    });

    return {
      ifbkMenu,
      callsToAction,
      cultureMenu,
      languesMenu,
      campusMenu,
      recentProgs,
      categoriesMenu,
      openModalEspace,
      closeModalEspace,
      closeWaring,
      youtubeSRC,

      progData,
      progDataNavReversered,
      openModalVid,
      closeModalVid,
    };
  },
});
</script>

<style scoped>
a:hover {
  text-decoration: underline !important;
}
</style>
