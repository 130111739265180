import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
// import Cookies from "js-cookie";
import axios from "axios";

const store = createStore({
  state: {
    error: "",
    modalState: false,
    modalState2: false,
    docAvailable: true,
    isLoading: true,
    //Données du filtree
    catSelectedIndex: 0,
    moisSelectedIndex: 0,
    warn: false,
    allLinks: [],

    //tranlation state variable
    lang_store: localStorage.getItem("_ls_laguage"),
    keyNav: 0,
  },
  plugins: [
    createPersistedState({
      paths: ["catSelectedIndex", "moisSelectedIndex"],
    }),
  ],

  getters: {
    getAllLinks: (state) => state.allLinks,
    getLangStore: (state) => {
      return state.lang_store;
    },
  },

  actions: {
    fetchAllLinks({ commit }) {
      axios
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}linksFiles/jsonFile/json_data.json?00004`
        )
        .then((r) => {
          commit("SET_ALL_LINKS", r.data);
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    },
  },
  mutations: {
    SET_ALL_LINKS(state, allLinks) {
      state.allLinks = allLinks;
    },
  },
});

export default store;
