<template>
  <metainfo>
    <template v-slot:title="{ content }"
      >{{
        content ? `${content} | Institut français de Bukavu` : `Halle des Grands Lacs`
      }}
    </template>
  </metainfo>
  <vue3-progress />
  <!-- <div :value="updateExists" :timeout="0">
    <button @click="refreshApp">Actualiser</button>
  </div> -->
  <div class="relative">
    <Navbar :key="$store.state.keyNav" />
    <!-- ommited -->
    <SocialChat icon :attendants="attendants">
      <template #header>
        <p class="font-medium text-base">{{ $t("footer_t13") }} 👋🏼</p>
      </template>

      <template #button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          aria-hidden="false"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
          />
        </svg>
      </template>
      <template #footer>
        <small v-html="$t('footer_t14')"></small>
      </template>
    </SocialChat>
    <div class="min-h-screen">
      <div
        :class="[
          $store.state.warn ? 'mt-[120px] sm:mt-[122px]' : 'mt-16 sm:mt-20',
          'text-gray-800  transition-all duration-300 ease-in-out',
        ]"
      >
        <router-view v-slot="{ Component }">
          <FadeInOut entry="center" exit="center" :duration="300" mode="out-in">
            <div class="gs_reveal" :key="$route.path">
              <component :is="Component" />
            </div>
          </FadeInOut>
        </router-view>
      </div>
    </div>

    <!-- Video Player -->

    <Footer />
  </div>
</template>

<script>
import { FadeInOut } from "vue3-transitions";
import update from "./mixins/update";
import { ref, watch, computed, defineComponent, provide } from "vue";
import { SocialChat } from "vue-social-chat";
import { useMeta } from "vue-meta";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import "vue3-carousel/dist/carousel.css";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import store from "@/store/store";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

// import About from "./components/About.vue";

export default defineComponent({
  name: "App",
  mixins: [update],

  components: {
    Navbar,
    Footer,
    SocialChat,
    FadeInOut,

    //About,
  },

  mounted() {
    this.$progress.finish();
  },
  created() {
    // eslint-disable-next-line
    this.$progress.start();
    this.$router.beforeEach((to, from, next) => {
      this.$progress.start();
      next();
    });
    // eslint-disable-next-line
    this.$router.afterEach((to, from) => {
      this.$progress.finish();
    });
  },
  setup() {
    const i18n = useI18n();
    provide("store", store);
    var { t } = useI18n({});
    provide("txt", t);

    store.subscribe((mutation, state) => {
      // Store the state object as a JSON string
      let store = {
        lang_store: state.lang_store,
      };
      localStorage.getItem("store", JSON.stringify(store));
    });

    var initial_lang = localStorage.getItem("_ls_laguage");

    // console.log("je detecte", initial_lang);

    async function setInitialLangue() {
      if (initial_lang == null) {
        localStorage.setItem("_ls_laguage", "fr");
        store.state.lang_store = localStorage.getItem("_ls_laguage");
      } else if (
        initial_lang !== "fr" &&
        initial_lang !== "sw" &&
        initial_lang !== "en"
      ) {
        localStorage.setItem("_ls_laguage", "fr");
        store.state.lang_store = localStorage.getItem("_ls_laguage");
      } else {
        return 0;
      }
    }

    setInitialLangue();

    watch(
      () => store.getters.getLangStore,
      () => {
        //watch the getter
        i18n.locale.value = store.getters.getLangStore;
      },
      {
        immediate: true,
      }
    );

    const route = useRoute();
    const _nameRoute = ref(route.hash);
    console.log(_nameRoute);

    computed(() => {
      if (_nameRoute.value === "#video") {
        return (store.state.modalState2 = true);
      } else {
        return (store.state.modalState2 = false);
      }
    });

    const attendants = [
      // {
      //   app: "whatsapp",
      //   label: "Responsable de l'accueil",
      //   name: "Alphonse Cibembe",
      //   number: "+243970277176",
      //   avatar: {
      //     src: "https://institutfrancaisbukavu.com/img/equipe/alph.jpg",
      //     alt: "Alphonse C",
      //   },
      // },
      {
        app: "messenger",
        label: "IFBukavu",
        name: "Facebook Messenger",
        number: "1525023701070120",
        avatar: {
          src: "https://institutfrancaisbukavu.com/img/icons/android-chrome-512x512.png",
          alt: "Jonathan M",
        },
      },
    ];
    const computedMeta = computed(() => ({
      // eslint-disable-next-line
      title: "Institut français de Bukavu, Halle des Grand Lacs",
      htmlAttrs: { lang: "fr", amp: true },
      description: "Descriptions",
      og: {
        title: "Institut français de Bukavu, Halle des Grand Lacs",
        description: "Vivre les Cultures avec l'Institut français de Bukavu",
        image: "https://www.institutfrancaisbukavu.com/img/logo/if_logo.jpg",
        url: "https://www.institutfrancaisbukavu.com/",
        type: "website",
      },
      twitter: {
        title: "Institut français de Bukavu, Halle des Grand Lacs",
        description: "Vivre les Cultures avec l'Institut français de Bukavu",
        image: "https://www.institutfrancaisbukavu.com/img/logo/if_logo.jpg",
        url: "https://www.institutfrancaisbukavu.com/",
        card: "summary",
      },
    }));
    useMeta(computedMeta);

    return {
      attendants,
    };
  },
});
</script>

<style>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scaleY(1);
  transition: transform 50ms, opacity 0ms;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
