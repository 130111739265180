<template>
  <router-link
    class="prog-card col-span-12 md:col-span-6 lg:col-span-3 group"
    :to="'/events/' + link.toString().slice(0, 12)"
  >
    <div class="--transition overflow-hidden mb-6 sm:mb-0 relative h-full w-full">
      <div class="--transition rainbow prog-card--border"></div>
      <span
        class="absolute animate-pulse z-0 top-0 bottom-0 left-0 right-0 w-full h-full bg-gray-800"
      ></span>
      <img
        class="--transition absolute z-1 overflow-hidden inset-0 object-cover w-full h-full scale-100 group-hover:scale-110"
        :src="$ressAPIimg + image"
      />

      <span
        class="absolute z-1 inset-0 w-full h-full bg-gradient-to-b from-transparent to-blackgray-800 transition duration-300 ease-out opacity-100"
      ></span>
      <span
        class="absolute z-1 inset-0 w-full h-full bg-gradient-to-b from-transparent to-blackgray-800 transition duration-300 ease-out opacity-100"
      ></span>
      <div class="flex justify-start mb-2">
        <span
          :style="'background-color :' + categorie[0].bgColor"
          class="text-white font-bold px-2 pt-1 inline relative text-[10px] mb-3 uppercase"
        >
          {{ categorie[0].name }}
        </span>
        <span
          :class="[
            state == 'true' ? ' bg-[#22c55ece]' : 'bg-gray-700',
            'ml-2 pb-0.5 rounded-md text-white font-medium px-2 pt-1 inline relative text-[10px] mb-3',
          ]"
        >
          <span v-if="state == 'true'" class="text-white">
            <span class="relative mr-1 inline-flex rounded-full h-2 w-2 bg-green-500">
              <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"
              ></span>
            </span>
            À venir</span
          >
          <span v-else>Passé</span>
        </span>
      </div>
      <span
        class="--transition transition-all group-hover:underline group-hover:line-clamp-3 relative mb-3 text-md font-bold leading-6 text-white line-clamp-2 text-left"
      >
        {{ name }}
      </span>
      <span class="relative flex justify-start text-xs text-white opacity-80">
        <span class="mr-1 max-w-[50%] flex justify-start">
          <span class="mr-2 flex justify-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 opacity-70"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              /></svg
          ></span>
          <span class="mt-0.5 whitespace-nowrap">
            {{ heure }}
          </span></span
        >
        <span class="font-medium">·</span>
        <span class="text-gray-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4 ml-1 opacity-50"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="{2}"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg> </span
        ><span class="ml-2 mt-0.5 truncate w-[110px]">
          {{ lieu }}
        </span>
      </span>
      <div class="absolute bottom-5 right-5">
        <img :src="$ressAPIimg + 'back/' + categorie[0].catIcon" alt="" width="20" />
      </div>
      <div class="mt-2 absolute bottom-5">
        <div class="flex justify-start w-full text-gray-200 text-xs">
          <span class="text-gray-100 opacity-80 my-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-gray-100 opacity-50"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </span>
          <span class="ml-2 mt-0.5">
            {{ date }}
          </span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProgCard",
  props: {
    state: String,
    id: Number,
    name: String,
    link: String,
    linkCode: Number,
    image: String,
    categorie: {
      type: Object,
      default: function () {
        return {
          id: Number,
          index: Number,
          name: String,
          catIcon: String,
          bgColor: String,
        };
      },
    },

    heure: String,
    jour: String,
    moi: String,
    date: String,
    lieu: String,
    viewsCount: Number,
    price: String,
    desc: String,
    tags: Array,
  },
});
</script>
