<template>
  <div class="relative cursor-pointer flex justify-center items-center space-x-1">
    <Popover v-slot="{ open }" class="z-30">
      <PopoverButton
        tabindex="-1"
        :class="open ? 'underline' : 'text-opacity-90  '"
        class="flex items-center space-x-1 text-cwhite hover:text-cyellow cursor-pointer focus:outline-none"
      >
        <div class="hover:underline flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-6 h-6 text-blackgray-700"
          >
            <path
              fill-rule="evenodd"
              d="M9 2.25a.75.75 0 01.75.75v1.506a49.38 49.38 0 015.343.371.75.75 0 11-.186 1.489c-.66-.083-1.323-.151-1.99-.206a18.67 18.67 0 01-2.969 6.323c.317.384.65.753.998 1.107a.75.75 0 11-1.07 1.052A18.902 18.902 0 019 13.687a18.823 18.823 0 01-5.656 4.482.75.75 0 11-.688-1.333 17.323 17.323 0 005.396-4.353A18.72 18.72 0 015.89 8.598a.75.75 0 011.388-.568A17.21 17.21 0 009 11.224a17.17 17.17 0 002.391-5.165 48.038 48.038 0 00-8.298.307.75.75 0 01-.186-1.489 49.159 49.159 0 015.343-.371V3A.75.75 0 019 2.25zM15.75 9a.75.75 0 01.68.433l5.25 11.25a.75.75 0 01-1.36.634l-1.198-2.567h-6.744l-1.198 2.567a.75.75 0 01-1.36-.634l5.25-11.25A.75.75 0 0115.75 9zm-2.672 8.25h5.344l-2.672-5.726-2.672 5.726z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="text-xs font-bold ml-1">
            <span v-if="store.state.lang_store === 'fr'"> FR </span>
            <span v-if="store.state.lang_store === 'sw'"> SW </span>
            <span v-if="store.state.lang_store === 'en'"> EN </span>
          </span>
        </div>

        <ChevronDownIcon
          :class="[open ? ' transform rotate-[180deg] ' : 'opacity-60 z-0']"
          class="ml-0 h-5 w-5 -mt-1 transition duration-150 ease-in-out group-hover:text-opacity-80"
          aria-hidden="true"
        />
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel tabindex="-1">
          <div class="relative">
            <div
              tabindex="-1"
              class="font-medium bg-white shadow-md border-2 border-gray-200 border-opacity-80 customShadow absolute right-0 z-30 p-2 mt-3 space-y-2 transform w-36 md:w-36 rounded-sm"
            >
              <div class="relative">
                <div
                  tabindex="-1"
                  class="tooltip-top transform rotate-180 customShadow"
                ></div>
                <PopoverButton
                  tabindex="-1"
                  @click="switchLang('fr')"
                  :class="[
                    store.state.lang_store === 'fr'
                      ? 'underline relative checked bg-gray-100 border-4 border-gray-100  font-bold'
                      : 'text-gray-500 ',
                    'hover:text-gray-900 text-left  hover:bg-opacity-5 w-full hover:underline  block px-4 py-3 text-sm capitalize hover:bg-gray-50 rounded-xl',
                  ]"
                >
                  Français
                </PopoverButton>
                <PopoverButton
                  tabindex="-1"
                  @click="switchLang('sw')"
                  :class="[
                    store.state.lang_store === 'sw'
                      ? 'underline relative checked bg-gray-100 border-4 border-gray-100  font-bold'
                      : '',
                    'hover:text-gray-900 text-left  hover:bg-opacity-5 w-full hover:underline block px-4 py-3 text-sm text-gray-500 capitalize hover:bg-gray-50 rounded-xl',
                  ]"
                >
                  Swahili
                </PopoverButton>
                <PopoverButton
                  tabindex="-1"
                  @click="switchLang('en')"
                  :class="[
                    store.state.lang_store === 'en'
                      ? 'underline relative checked bg-gray-100 border-4 border-gray-100  font-bold'
                      : '',
                    'hover:text-gray-900 text-left  hover:bg-opacity-5 w-full hover:underline block px-4 py-3 text-sm text-gray-500 capitalize hover:bg-gray-50 rounded-xl',
                  ]"
                >
                  English
                </PopoverButton>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>

  <!-- 
    <span>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
            >
            <path
                stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                  />
                </svg>
              </span>
              <p>
                <span class="text-xs font-medium ">FR</span>
              </p> -->
</template>

<script>
import { ChevronDownIcon } from "@heroicons/vue/solid";
import { inject } from "vue";

export default {
  name: "TranslateC",
  components: {
    ChevronDownIcon,
  },
  setup() {
    var store = inject("store", store);

    function switchLang(lang) {
      localStorage.setItem("_ls_laguage", lang);
      store.state.lang_store = lang;
      store.state.keyNav = !store.state.keyNav;
    }
    function setLangSw() {
      localStorage.setItem("_ls_laguage", "sw");
      store.state.lang_store = "sw";
      store.state.keyNav = !store.state.keyNav;
      // location.reload();
    }

    function setLangFr() {
      localStorage.setItem("_ls_laguage", "fr");
      store.state.lang_store = "fr";
      store.state.keyNav = !store.state.keyNav;

      // location.reload();
    }

    function setLangEN() {
      localStorage.setItem("_ls_laguage", "en");
      store.state.lang_store = "en";
      store.state.keyNav = !store.state.keyNav;

      // location.reload();
    }
    return {
      store,
      setLangSw,
      setLangFr,
      setLangEN,
      switchLang,
    };
  },
};
</script>
