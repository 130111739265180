<template>
  <div
    v-for="index in countRepete"
    :key="index"
    class="mb-5 sm:mb-0 relative flex overflow-hidden flex-col items-start justify-end col-span-12 px-5 pb-6 cursor-pointer h-80 md:col-span-6 lg:col-span-3 group"
  >
    <span
      class="absolute animate-pulse z-0 top-0 bottom-0 left-0 right-0 w-full h-full bg-gray-800"
    ></span>
    <span
      class="absolute z-1 inset-0 w-full h-full bg-gradient-to-b from-transparent to-gray-900 transition duration-300 ease-out opacity-100"
    ></span>
    <div class="abolute -bottom-6 left-0 right-0 z-10 w-full">
      <p class="animate-pulse opacity-20 z-10 bg-white w-12 h-4 rounded-sm mb-4"></p>
      <p class="animate-pulse opacity-10 z-10 bg-white w-full h-3 rounded-sm"></p>
      <p
        class="animate-pulse opacity-10 z-10 bg-white w-auto h-2 mt-2 mr-5 rounded-sm"
      ></p>
      <div class="flex justify-between w-100 mt-4">
        <p
          class="animate-pulse opacity-5 z-10 bg-white w-1/2 h-[8px] mt-2 mr-5 rounded-sm"
        ></p>
        <p
          class="animate-pulse opacity-5 z-10 bg-white w-1/2 h-[8px] mt-2 mr-5 rounded-sm"
        ></p>
      </div>
      <p
        class="animate-pulse opacity-5 z-10 bg-white w-1/2 h-[8px] mt-2 mr-5 rounded-sm"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonEvents",
  props: {
    countRepete: Number,
  },
  setup() {},
};
</script>
