<template>
  <div class="container-tail relative overflow-x-hidden">
    <!-- Section 1 -->

    <section class="relative w-full bg-bluegray-50">
      <div class="relative bg-cover bg-center bg-no-repeat">
        <div class="relative mx-auto max-w-6xl px-0 py-0 pt-4 sm:py-16">
          <div class="w-full px-4 pb-14 pt-20 md:w-1/2">
            <div class="mx-auto max-w-lg md:max-w-lg xl:mx-0">
              <div class="text-md mb-2 mt-2 font-medium italic text-blue">
                <span class="">Liberté </span>
                <span class="text-2xl text-gray-400"> . </span>
                <span> Créativité</span>
                <span class="text-2xl text-gray-400"> . </span>
                <span> Diversité </span>
              </div>
              <h1
                v-html="$t('home_t1')"
                class="-first-letter1 gs_reveal my-6 text-3xl font-black tracking-tight text-gray-800 sm:text-[45px]"
              ></h1>
              <p class="gs_reveal mb-8 mt-4 max-w-md text-gray-500">
                {{ $t("home_t2") }}
              </p>
              <div
                class="flex flex-col items-center justify-start sm:flex-row sm:space-x-8"
              >
                <router-link
                  to="agenda#all"
                  class="gs_reveal ifb-button py-auto inline-flex h-10 w-full items-center justify-center whitespace-nowrap border border-transparent bg-blue px-4 py-2 text-base text-white shadow-sm hover:bg-blackgray-700 sm:w-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-4 w-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                    />
                  </svg>

                  <span class="text-md ml-3 mt-auto font-medium sm:mt-0">
                    {{ $t("menu_t10") }}
                  </span>
                </router-link>

                <router-link
                  to="/about"
                  class="text-md mt-6 flex w-full items-center justify-center font-medium hover:text-blue sm:ml-8 sm:mt-0 sm:w-auto"
                  ><span class="border-blackgray border-b-2 font-medium">{{
                    $t("menu_t6")
                  }}</span
                  ><svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="ml-1 h-4 text-blackgray-500"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path></svg
                ></router-link>
              </div>

              <div class="relative z-20 mt-12 pl-0 sm:p-0">
                <p
                  class="mb-4 text-center text-sm font-medium text-gray-500 md:text-left xl:mt-0"
                >
                  {{ $t("home_t6") }}
                </p>

                <div class="mx-auto w-full max-w-2xl px-0 text-center sm:mt-0 sm:px-0">
                  <Carousel
                    :items-to-show="4"
                    :wrapAround="true"
                    :autoplay="1000"
                    :transition="500"
                    :pauseAutoplayOnHover="true"
                    :mouseDrag="true"
                    :touchDrag="true"
                  >
                    <Slide v-for="slide in partners" :key="slide">
                      <div
                        class="displayBox flex h-20 cursor-grab items-center justify-center border border-gray-100 bg-white p-6 text-gray-400 shadow-sm sm:p-6"
                      >
                        <span class="sr-only">{{ slide.name }}</span>
                        <img
                          :src="slide.img"
                          :alt="slide.name"
                          style="with: 70px !important"
                          class="transition-all hover:scale-125"
                        />
                      </div>
                    </Slide>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>

          <div class="right-0 top-0 h-full lg:absolute lg:w-1/2">
            <div class="relative h-full w-full">
              <img
                class="h-full w-full object-cover"
                src="/img/content/if_img16.jpg"
                alt=""
              />
              <a
                href="#_"
                class="group absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 transform items-stretch justify-start overflow-hidden whitespace-nowrap rounded bg-white shadow-xl transition duration-200 hover:scale-105 hover:shadow-2xl"
                @click="openModalVid"
              >
                <div
                  class="min-h-12 relative flex w-24 items-center justify-center md:h-24 md:w-28"
                >
                  <img
                    src="/img/content/fond1.jpg"
                    class="absolute inset-0 bottom-0 top-0 z-0 min-h-full w-full object-cover opacity-75 transition duration-200 group-hover:opacity-50"
                    alt="image"
                  />
                  <svg
                    class="relative z-10 fill-current text-white"
                    width="40"
                    height="40"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36 6C19.458 6 6 19.458 6 36C6 52.542 19.458 66 36 66C52.542 66 66 52.542 66 36C66 19.458 52.542 6 36 6ZM36 60C22.767 60 12 49.233 12 36C12 22.767 22.767 12 36 12C49.233 12 60 22.767 60 36C60 49.233 49.233 60 36 60Z"
                    ></path>
                    <path
                      d="M27 45.5873C27 47.9436 29.5919 49.3801 31.59 48.1313L46.9296 38.544C48.8096 37.369 48.8096 34.631 46.9296 33.456L31.59 23.8687C29.5919 22.6199 27 24.0564 27 26.4127V45.5873Z"
                    ></path>
                  </svg>
                </div>
                <div
                  class="flex flex-col items-start justify-center px-4 py-4 text-gray-600 sm:py-4"
                >
                  <h3 class="text-md font-medium tracking-tight text-blue">
                    {{ $t("home_t4") }}
                  </h3>
                  <p class="font-base text-sm text-gray-500">
                    {{ $t("home_t5") }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="relative hidden w-full bg-white sm:pb-16">
      <div class="relative mx-auto max-w-6xl px-1">
        <div
          class="relative z-10 flex w-full flex-col items-start justify-start px-3 pt-4 md:flex-row md:items-stretch md:space-x-16 md:pt-8 lg:pt-12"
        >
          <div
            class="mx-auto w-full max-w-lg pt-16 md:mx-0 md:w-1/2 md:max-w-none md:pb-12"
          >
            <div class="gs_reveal flex items-center space-x-5">
              <div class="h-1 w-5 bg-blue sm:w-5"></div>
              <p class="text-xs font-medium uppercase tracking-wide text-blue sm:text-xs">
                {{ $t("home_t0") }}
              </p>
            </div>
            <h1
              v-html="$t('home_t1')"
              class="first-letter1 gs_reveal my-6 text-3xl font-black tracking-tight text-gray-800 sm:text-4xl"
            ></h1>
            <p class="gs_reveal mb-8 mt-4 max-w-md text-gray-500">
              {{ $t("home_t2") }}
            </p>
            <router-link
              to="/about"
              class="gs_reveal text-md hover: mt-10 flex items-center tracking-tight underline transition duration-150 ease-out sm:mt-0"
            >
              <svg
                class="mr-2 h-6 w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="mt-1 font-medium">{{ $t("home_t3") }}</span>
            </router-link>
          </div>
          <div
            class="relative mt-8 flex h-[80px] w-full items-center justify-center overflow-visible md:block md:h-[400px] md:w-1/2"
          >
            <img
              src="/img/content/if_img29.png"
              class="gs_reveal -cover absolute bottom-6 right-0 z-20 mx-auto hidden h-[150px] w-[150px] max-w-lg rounded object-center sm:bottom-28 sm:-mb-20 md:absolute md:-top-28 md:left-0 md:mx-0 md:block md:h-[700px] md:w-full md:max-w-none"
              alt="image"
            />

            <div
              class="absolute right-5 top-0 -mr-10 -mt-10 hidden h-32 w-32 overflow-hidden rounded shadow-2xl md:right-10"
            >
              <img
                src="/img/content/if_img3.jpg"
                class="absolute inset-0 z-0 h-full w-full object-cover object-center"
                alt="image"
              />
            </div>
            <div
              class="bar1 hidden h-4/5 w-4/5 before:-bottom-5 before:-right-5 before:h-8 before:w-48 after:-right-0 after:bottom-0 after:h-8 after:w-40 sm:h-full sm:w-full sm:before:-bottom-10 sm:before:-right-0 sm:before:h-12 sm:before:w-56 sm:after:-bottom-10 sm:after:h-12 sm:after:w-40"
            ></div>
            <img
              src="/img/bars/7.png"
              alt=""
              class="absolute bottom-8 right-1 block h-[70px] w-[100px] rotate-12 transform md:hidden md:w-[150px] md:rotate-0"
            />

            <div
              class="gs_reveal absolute bottom-0 z-20 -mb-12 px-4 md:left-0 md:-ml-36 md:px-0"
            >
              <a
                href="#_"
                class="group flex transform items-stretch justify-start overflow-hidden rounded bg-white shadow-xl transition duration-200 hover:scale-105 hover:shadow-2xl"
                @click="openModalVid"
              >
                <div class="relative flex h-24 w-28 items-center justify-center">
                  <img
                    src="/img/content/fond1.jpg"
                    class="absolute inset-0 z-0 h-full w-full object-cover object-center opacity-75 transition duration-200 group-hover:opacity-50"
                    alt="image"
                  />
                  <svg
                    class="relative z-10 fill-current text-white"
                    width="40"
                    height="40"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36 6C19.458 6 6 19.458 6 36C6 52.542 19.458 66 36 66C52.542 66 66 52.542 66 36C66 19.458 52.542 6 36 6ZM36 60C22.767 60 12 49.233 12 36C12 22.767 22.767 12 36 12C49.233 12 60 22.767 60 36C60 49.233 49.233 60 36 60Z"
                    ></path>
                    <path
                      d="M27 45.5873C27 47.9436 29.5919 49.3801 31.59 48.1313L46.9296 38.544C48.8096 37.369 48.8096 34.631 46.9296 33.456L31.59 23.8687C29.5919 22.6199 27 24.0564 27 26.4127V45.5873Z"
                    ></path>
                  </svg>
                </div>
                <div class="flex flex-col items-start justify-center px-4 text-gray-600">
                  <h3 class="text-md font-medium tracking-tight">
                    {{ $t("home_t4") }}
                  </h3>
                  <p class="font-base text-sm text-gray-500">
                    {{ $t("home_t5") }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          class="relative z-0 flex items-center justify-start px-3 py-12 md:px-0 md:py-0 lg:px-2"
        >
          <div
            class="bg-blackgray relative z-10 mx-auto -mt-12 w-full max-w-lg px-4 pb-8 pt-16 sm:px-8 sm:py-4 md:mx-0 md:mt-0 md:w-1/2 md:max-w-none md:py-12"
          >
            <p
              class="text-md -100 mb-4 mt-4 text-center font-medium xl:mt-0 xl:text-left"
            >
              {{ $t("home_t6") }}
            </p>
            <div
              class="flex w-full items-start justify-center space-x-8 text-white md:justify-start"
            >
              <img
                src="/img/partners/canal+.jpg"
                alt=""
                class="gs_reveal h-[45px] w-[110px]"
              />
              <div class="sm:flex-center flex">
                <img
                  src="/img/partners/TMB.jpg"
                  alt=""
                  class="gs_reveal ml-1 hidden h-[45px] sm:flex"
                />
                <img
                  src="https://rendezvouscampus.institutfrancais-kinshasa.org/images/logoFranceRDC.jpg"
                  alt=""
                  class="gs_reveal hidden h-[45px] w-[100px] xl:ml-8 xl:flex"
                />

                <img
                  src="https://storage.googleapis.com/images.businesspagescongo.com/2eb7d081-cfc3-4c19-80c5-570703f0dba9.png"
                  alt=""
                  class="gs_reveal h-[45px] w-[180px] sm:ml-8"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 2 -->
    <section class="relative mt-0 w-full bg-white py-16 sm:py-16">
      <!-- <div class="absolute inset-0 h-full w-full opacity-10"></div> -->
      <div class="relative mx-auto max-w-6xl px-4 md:px-3">
        <img
          class="absolute left-4 top-0 -mt-24 w-[30px] sm:right-0 sm:-mt-20 sm:w-[50px] lg:block"
          src="/img/bars/4.png"
        />
        <div class="flex items-center justify-center space-x-5">
          <div class="h-1 w-5 bg-bluegray-300"></div>
          <p class="text-xs font-medium uppercase tracking-wide text-blue">
            {{ $t("home_t7") }} 👇
          </p>
        </div>
        <div class="gs_reveal mb-3">
          <h2
            class="row mt-3 items-end justify-start text-center text-3xl font-black sm:flex sm:justify-center sm:text-4xl lg:text-4xl"
          >
            <span class="first-letter1 sm:mt-4">{{ $t("home_t8") }}</span>
            <span class="ml-3 mt-2 flex justify-center pb-0 sm:mt-4">
              <span class="mr-2 text-gray-300">« </span>
              <span class="text-blue">
                <VueWriter
                  :array="textHome1"
                  :caret="underscore"
                  :eraseSpeed="50"
                  :typeSpeed="100"
                  class="text-cursor text-blue"
                />
              </span>
              <span class="ml-2 text-gray-300"> »</span>
            </span>
          </h2>
        </div>

        <div class="mb-4 flex w-full justify-center pb-5 sm:mb-8"></div>

        <div class="w-full gap-8 sm:grid sm:grid-cols-12 md:gap-8" v-if="!isLoading">
          <ProgCard v-for="prog in progData.slice(0, 4)" :key="prog.id" v-bind="prog" />
        </div>
        <div class="flex justify-center" v-else>
          <div class="w-full sm:grid sm:grid-cols-12 md:gap-6">
            <SkeletonEvents :countRepete="4" />
          </div>
        </div>
      </div>

      <router-link
        to="/agenda#all"
        class="text-md mt-10 flex items-center justify-center font-medium text-blue"
        ><span class="border-blackgray border-b-2 font-medium">{{ $t("home_t9") }}</span
        ><svg viewBox="0 0 20 20" fill="currentColor" class="ml-1 h-4">
          <path
            fill-rule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path></svg
      ></router-link>
    </section>

    <!-- Section 3 -->
    <section class="flex w-full bg-bluegray-50">
      <div class="relative mx-auto max-w-6xl px-4 py-16 sm:px-6 sm:py-16 lg:px-8">
        <img
          class="absolute right-4 top-0 -mt-8 w-[30px] sm:right-0 sm:-mr-60 sm:-mt-24 sm:w-[50px] lg:block"
          src="/img/bars/2.png"
        />
        <div class="text-center">
          <div class="gs_reveal mb-2 hidden items-center justify-center space-x-5">
            <div class="h-1 w-5 bg-bluegray-200"></div>
            <p class="text-xs font-medium uppercase tracking-wide text-ecume">
              {{ $t("home_t10") }}
            </p>
          </div>
          <div
            class="gs_reveal mx-auto inline-flex w-full items-end justify-center text-center"
          >
            <div class="mx-auto inline-flex w-full items-end justify-center text-center">
              <img
                class=""
                v-for="(img, index) in progData.slice(0, 4)"
                :key="img"
                :src="$ressAPIimg + img.image"
                :class="progDataRecentImg[index].class"
              />
              <img
                src="/img/content/img28.jpg"
                class="relative h-20 w-20 rounded-full border-4 border-white object-cover md:h-24 md:w-24"
              />
            </div>
          </div>
          <h3
            class="first-letter1 gs_reveal mt-3 text-center text-3xl font-black sm:text-4xl lg:text-4xl"
          >
            {{ $t("home_t11") }} <br />
            <span class="relative inline-block px-2 py-0"
              ><span class="relative text-2xl text-ecume underline sm:text-3xl">{{
                $t("home_t12")
              }}</span></span
            >
          </h3>
          <p class="gs_reveal text-md mx-auto mb-10 mt-5 max-w-xl text-gray-500">
            {{ $t("home_t13") }}
          </p>

          <div class="">
            <button
              @click="openModalProg()"
              class="gs_reveal ifb-button py-auto inline-flex h-10 w-full items-center justify-center whitespace-nowrap border border-transparent bg-blue px-4 py-2 text-base text-white shadow-sm hover:bg-blackgray-700 sm:w-auto"
            >
              <span
                ><img src="/img/icons/pdf.png" alt="" width="25" class="mt-auto"
              /></span>

              <span class="text-md ml-3 mt-auto font-medium sm:mt-0">
                {{ $t("home_t14") }}
              </span>
            </button>
          </div>

          <TransitionRoot appear :show="isOpenModalProg" as="template">
            <Dialog as="div" @close="closeModalProg">
              <div class="fixed inset-0 z-50 overflow-y-auto">
                <div class="min-h-screen px-4 text-center">
                  <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                  >
                    <DialogOverlay
                      class="fixed inset-0 bg-gray-900 bg-opacity-70 backdrop-blur-md backdrop-filter"
                    />
                  </TransitionChild>

                  <span class="inline-block h-screen align-middle" aria-hidden="true">
                    &#8203;
                  </span>

                  <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                  >
                    <div
                      class="my-8 inline-block w-full max-w-xl transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all"
                    >
                      <DialogTitle
                        as="h3"
                        class="text-2xl font-black leading-6 text-gray-900 sm:text-3xl"
                      >
                        Mois de <span class="text-cumulus">Janvier & Février</span> 2024
                      </DialogTitle>
                      <div class="mt-4">
                        <p class="text-lg">
                          Voici le programme culturel de janvier et février à l'Institut
                          français de Bukavu et Hors les murs.
                        </p>

                        <p class="mb-2 mt-4">
                          Deuxième mois de l'année, dixième programme 😍🚩
                        </p>
                      </div>

                      <div class="relative my-10 mt-4 min-h-full w-full sm:min-h-[300px]">
                        <div
                          class="-z-1 opacity-500 absolute bottom-0 left-0 top-0 w-[99%] animate-pulse bg-gray-600 bg-gradient-to-t from-transparent to-gray-900"
                        ></div>
                        <img
                          class="w-full"
                          src="/img/prog/janvier-fevrier-2024.jpg"
                          alt=""
                        />
                      </div>
                      <div class="mt-4 flex justify-start">
                        <button
                          type="button"
                          class="hover:bg-blue-200 focus-visible:ring-blue-500 inline-flex justify-center border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                          @click="closeModalProg"
                        >
                          Quitter
                        </button>
                        <button
                          type="button"
                          class="ifb-button focus-visible:ring-blue-500 ml-5 inline-flex justify-center border border-transparent bg-blue px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                        >
                          <a
                            target="_blank"
                            download="Programme Octobre 2023 - Institut français de Bukavu"
                            href="/img/prog/janvier-fevrier-2024.jpg"
                            class="flex justify-start space-x-2"
                          >
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                stroke-width="2"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                />
                              </svg>
                            </span>
                            <span> Télécharger </span>
                          </a>
                        </button>
                      </div>
                    </div>
                  </TransitionChild>
                </div>
              </div>
            </Dialog>
          </TransitionRoot>
        </div>
      </div>
    </section>

    <section class="flex w-full">
      <div class="relative mx-auto max-w-6xl px-4 md:px-3">
        <img
          class="absolute left-4 top-0 -mt-8 w-[40px] sm:-mr-60 sm:-mt-24 sm:w-[100px] lg:block"
          src="/img/bars/3.png"
        />
        <section class="overflow-hidden bg-white py-16 sm:py-24">
          <div class="mx-auto">
            <div class="-m-8 mb-16 flex flex-wrap items-center sm:mb-20">
              <div class="w-full px-8 md:w-1/2">
                <h2
                  class="font-heading tracking-px-n text-3xl font-black leading-tight md:max-w-xl md:text-[42px]"
                  v-html="$t('home_t22')"
                ></h2>
              </div>
              <div class="w-full px-8 pt-8 sm:pt-0 md:w-1/2">
                <p class="text-md leading-relaxed text-gray-500 sm:text-lg md:max-w-lg">
                  {{ $t("home_t23") }}
                </p>
              </div>
            </div>
            <div class="-m-3 flex flex-wrap">
              <div class="w-full p-3 md:w-1/3">
                <router-link to="/langues" class="flex h-full justify-center md:block">
                  <div class="relative h-full overflow-hidden rounded-md">
                    <img
                      class="h-[250px] transform object-cover transition duration-1000 ease-in-out hover:scale-110 md:h-full md:w-full"
                      src="/img/content/langues/2.jpg"
                      alt=""
                    />
                    <div class="absolute bottom-0 left-0 w-full px-11 pb-10">
                      <div
                        class="shadow-5xl rounded-md bg-blackgray-700 bg-opacity-40 px-8 py-4"
                        style="
                          -webkit-backdrop-filter: blur(5px);
                          backdrop-filter: blur(5px);
                        "
                      >
                        <h3 class="text-md text-center font-bold text-white">
                          {{ $t("home_t18") }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="w-full p-3 md:w-1/3">
                <router-link
                  to="/certifications"
                  class="flex h-full justify-center md:block"
                >
                  <div class="relative h-full overflow-hidden rounded-md">
                    <img
                      class="h-[250px] transform object-cover transition duration-1000 ease-in-out hover:scale-110 sm:h-full md:w-full"
                      src="/img/content/langues/1.jpg"
                      alt=""
                    />
                    <div class="absolute bottom-0 left-0 w-full px-11 pb-10">
                      <div
                        class="shadow-5xl rounded-md bg-blackgray-700 bg-opacity-40 px-8 py-4"
                        style="
                          -webkit-backdrop-filter: blur(5px);
                          backdrop-filter: blur(5px);
                        "
                      >
                        <h3 class="text-md text-center font-bold text-white">
                          {{ $t("home_t20") }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="w-full p-3 md:w-1/3">
                <router-link
                  to="/campusfrance"
                  class="flex h-full justify-center md:block"
                >
                  <div class="relative h-full overflow-hidden rounded-md">
                    <img
                      class="h-[250px] scale-105 transform object-cover transition duration-1000 ease-in-out hover:scale-125 sm:h-full md:w-full"
                      src="/img/content/campus/6.jpg"
                      alt="Campus France"
                    />
                    <div class="absolute bottom-0 left-0 w-full px-11 pb-10">
                      <div
                        class="shadow-5xl rounded-md bg-blackgray-700 bg-opacity-40 px-8 py-4"
                        style="
                          -webkit-backdrop-filter: blur(5px);
                          backdrop-filter: blur(5px);
                        "
                      >
                        <h3 class="text-md text-center font-bold text-white">
                          {{ $t("home_t15") }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue3-carousel";

import store from "@/store/store";
import SkeletonEvents from "@/components/states/SkeletonEvents.vue";
import axios from "axios";
import { ref, defineComponent, onBeforeMount } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

const progDataRecentImg = ref([
  {
    class:
      "absolute transform translate-x-24 -ml-2 sm:ml-6 rounded-full w-12 h-12 md:w-16 md:h-16 border-4 border-white",
  },
  {
    class:
      "absolute transform -translate-x-24 ml-2 sm:-ml-6 rounded-full w-12 h-12 md:w-16 md:h-16 border-4 border-white",
  },
  {
    class:
      "absolute transform ml-6 -translate-x-16 rounded-full w-16 h-16 md:w-20 md:h-20 border-4 border-white",
  },
  {
    class:
      "absolute transform -ml-6 translate-x-16 rounded-full w-16 h-16 md:w-20 md:h-20 border-4 border-white",
  },
]);
const isOpenModalProg = ref(false);
var isLoading = ref(true);

async function closeModalProg() {
  isOpenModalProg.value = false;
}
async function closeModalVid() {
  store.state.modalState2 = false;
}
async function openModalProg() {
  isOpenModalProg.value = true;
}
async function openModalVid() {
  store.state.modalState2 = true;
}

const partners = ref([
  {
    name: "",
    img: "/img/partners/CD_ambaFrance.png",
  },
  {
    name: "",
    img: "/img/partners/brasimba.jpg",
  },
  {
    name: "",
    img: "/img/partners/TMB.jpg",
  },
  {
    name: "",
    img: "/img/partners/ifkin.jpg",
  },

  {
    name: "",
    img: "/img/partners/ifgoma_1.png",
  },

  {
    name: "",
    img: "/img/partners/iflushi.png",
  },
  {
    name: "",
    img: "img/partners/frrdc.jpg",
  },
  {
    name: "",
    img: "/img/partners/internews.png",
  },
  {
    name: "",
    img: "/img/partners/keshocongo.jpeg",
  },
  {
    name: "",
    img: "/img/partners/unicef.png",
  },
  {
    name: "",
    img: "/img/partners/ifparis.svg",
  },
]);

export default defineComponent({
  /* eslint-disable */
  // eslint-disable-next-line
  name: "HomeView",
  props: {
    msg: String,
  },
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    SkeletonEvents,
    Carousel,
    Slide,
  },
  methods: {},
  setup() {
    var progData = ref([
      {
        id: 0,
        name: "",
        link: "",
        categorie: [
          {
            id: 3,
            name: "",
            catIcon: "",
            bgColor: "",
          },
        ],
        heure: "",
        date: "",
        lieu: "",
        viewsCount: 0,
        price: "",
        desc: "",
        tags: [""],
      },
    ]);

    // console.log("=============== Process", process.env.VUE_APP_API_ENDPOINT);
    async function getDataJson() {
      axios
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}/frontFiles/jsonFile/json_data.json?00004`
        )
        .then((r) => {
          var progDataJson = r.data;
          progData.value = progDataJson;
          isLoading.value = false;
        })
        .catch((err) => {
          console.log("==========", err);
        });
    }
    onBeforeMount(() => {
      getDataJson();
    });
    function onReady() {
      this.$refs.youtube.playVideo();
    }

    // var progDataReversered = computed(() => {
    //   return progData.value.slice().reverse();
    // });

    //progDataJson

    return {
      textHome1: ["Idée", "Numérique", "Cinéma", "Livre", "Spetacle vivant"],
      isLoading,
      isOpenModalProg,
      openModalProg,
      closeModalProg,
      openModalVid,
      closeModalVid,
      //variable liste programme
      progData,
      // progDataReversered,
      progDataRecentImg,

      onReady,
      partners,
    };
  },
});
</script>

<style scoped>
.carousel__slide img {
  width: 100px !important;
}

@media only screen and (max-width: 1200px) {
  .carousel__slide img {
    width: 200px !important;
  }
  .carousel__slide {
    width: 120px !important;
  }
}
</style>
