<template>
  <div>
    <TransitionRoot appear :show="isOpenModalVid" as="template">
      <Dialog as="div" @close="closeModalVid">
        <div class="fixed inset-0 z-50 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay
                class="fixed inset-0 bg-gray-900 bg-opacity-70 backdrop-filter backdrop-blur-md"
              />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl"
              >
                <DialogTitle as="h3" class="text-2xl font-medium leading-6 text-gray-900">
                  <span v-html="$t('vid_t1')"> </span>
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ $t("vid_t2") }}
                  </p>
                  <div class="bg-white mt-6 flex justify-left items-center flex-wrap">
                    <span
                      class="mr-2 my-2 px-2 py-1 bg-gray-200 hover:bg-gray-300 text-xs font-bold text-gray-600"
                    >
                      #Art
                    </span>
                    <span
                      class="m-2 px-3 py-1 bg-green-200 hover:bg-green-300 text-xs font-bold text-green-600"
                    >
                      #Culture
                    </span>
                    <span
                      class="m-2 px-3 py-1 bg-yellow-200 hover:bg-yellow-300 text-xs font-bold text-yellow-600"
                    >
                      #Gens
                    </span>
                  </div>
                </div>

                <div class="my-8">
                  <div class="my-10 relative min-h-full sm:min-h-[250px] w-full mt-4">
                    <div class="absolute top-0 right-0 left-0 bottom-0 w-full"></div>
                    <div
                      class="absolute -z-1 w-[99%] top-0 animate-pulse left-0 bottom-0 bg-gradient-to-t from-transparent to-gray-900 opacity-500 bg-gray-600"
                    ></div>
                    <YouTube
                      :src="youtubeSRC"
                      @ready="onReady"
                      ref="youtube"
                      width="100%"
                      height="250px"
                    />
                  </div>
                </div>
                <div class="mt-4">
                  <a
                    class="inline-flex cursor-pointer -mt-32 justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    @click="closeModalVid"
                  >
                    {{ $t("btn_t1") }}
                  </a>
                  <a
                    class="ml-5 ifb-button inline-flex justify-center px-4 py-2 text-sm font-medium border text-white bg-blue border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UCk_tSSiUm19dEPre5BmBwlA"
                      class="flex font-medium justify-center w-full"
                    >
                      <div class="flex justify-center">
                        <span>
                          {{ $t("btn_t3") }}
                        </span>
                      </div>
                    </a>
                  </a>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import YouTube from "vue3-youtube";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
//exports
export default {
  name: "VideoPlayer",
  props: {
    youtubeSRC: String,
    //https://youtu.be/3sv21LD5osA
    //https://youtu.be/zg2a_W8Il3I
    isOpenModalVid: Boolean,
    closeModalVid: Function,
  },
  components: {
    YouTube,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  methods: {
    onReady() {
      this.$refs.youtube.playVideo();
    },
  },
  setup(props) {
    // functions
    props.closeModalVid();
  },
};
</script>
