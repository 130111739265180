import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
//https://youtu.be/Pcrzy1f1fK4

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView
	},
	{
		path: "/about",
		name: "about",
		component: () => import(/*webpackChunkName:"about"*/ "../views/AboutView.vue")
	},
	{
		path: "/agenda",
		name: "agenda",
		component: () => import(/*webpackChunkName:"events"*/ "../views/AgendaView.vue")
	},
	{
		path: "/y-2022/:link",
		alias: "/events/:link",
		name: "singleAgenda",
		component: () => import(/*webpackChunkName:"events"*/ "../views/agenda/SingleAgendaView.vue")
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import(/*webpackChunkName:"contact"*/ "../views/ContactView.vue")
	},
	{
		path: "/langues",
		name: "langues",
		component: () => import(/*webpackChunkName:"langues"*/ "../views/others/CentreLanguesView.vue")
	},
	{
		path: "/certifications",
		name: "certifications",
		component: () => import(/*webpackChunkName:"certifications"*/ "../views/others/CertificationsView.vue")
	},
	{
		path: "/campusfrance",
		name: "campusfrance",
		component: () => import(/*webpackChunkName:"campusfrance"*/ "../views/others/CampusFranceView.vue")
	},
	{
		path: "/la-pepiniere",
		name: "la-pepiniere",
		meta: { requiresAuth: true },
		component: () => import(/*webpackChunkName:"campusfrance"*/ "../views/others/LaPepiniereView.vue")
	},
	{
		path: "/video",
		name: "video",
		component: () => import(/*webpackChunkName:"video"*/ "../views/others/VideoView.vue")
	},
	{
		path: "/faq",
		name: "faq",
		component: () => import(/*webpackChunkName:"faq"*/ "../views/others/FaqView.vue")
	},

	{
		path: "/mediatheque",
		name: "mediatheque",
		component: () => import(/*webpackChunkName:"mediatheque"*/ "../views/others/MediathequeView.vue")
	},
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: () => import(/*webpackChunkName:"NotFound"*/ "../views/NotFound.vue")
	},
	{
		path: "/tmp",
		name: "TmpFile",
		component: () => import(/*webpackChunkName:"NotFound"*/ "../views/trash/TmpFile.vue")
	}
];

//process.env.BASE_URL
const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// eslint-disable-next-line no-unused-vars
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (savedPosition) {
					return resolve(savedPosition);
				} else if (to.hash) {
					console.log("hash");
					return resolve({
						offset: { x: 0, y: 1000 }
					});
				} else {
					resolve({ behavior: "smooth", top: 0 });
				}
			}, 300);
		});
	}
});

router.beforeEach((to, from, next) => {
	next();

	let imgMeta = document.querySelector("meta[property='og:image']").getAttribute("content");
	let titleMeta = document.querySelector("meta[property='og:title']").getAttribute("content");
	let urlMeta = document.querySelector("meta[property='og:url']").getAttribute("content");
	to.imgMeta;
	to.titleMeta;
	to.urlMeta;
	// let titleW = document.querySelector("meta[property='twitter:title']").getAttribute("content");
	// let descriptionW = document.querySelector("meta[property='twitter:description']").getAttribute("content");
	// document.title = titleMeta;
	// document.querySelector("meta[property='og:url']").getAttribute("content") = "Vince";

	console.log(imgMeta, titleMeta, urlMeta);
	console.log(document.title);
});

export default router;
