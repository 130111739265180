<template>
  <section>
    <div class="footerBg">
      <div class="mx-auto max-w-6xl px-4 pt-16 md:px-3 lg:px-3">
        <div class="grid row-gap-10 mb-8 lg:grid-cols-6 relative">
          <img
            class="absolute top-0 right-0 sm:right-0 -mt-28 sm:-mt-32 lg:block w-[40px] sm:w-[50px]"
            src="/img/bars/4.png"
          />
          <div
            class="grid grid-cols-2 gap-2 row-gap-8 lg:col-span-4 md:grid-cols-4 sm:mr-24"
          >
            <div>
              <p class="font-medium tracking-wide text-gray-300">
                {{ $t("menu_t1") }}
              </p>
              <ul class="mt-2 space-y-2">
                <li>
                  <router-link
                    to="/about"
                    class="text-gray-500 font-medium transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    {{ $t("footer_t1") }}
                  </router-link>
                </li>

                <li>
                  <a
                    @click="openModalVid"
                    class="cursor-pointer text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    {{ $t("footer_t2") }}
                  </a>
                </li>
                <li>
                  <router-link
                    to="/contact"
                    class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    {{ $t("footer_t3") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div>
              <p class="font-medium tracking-wide text-gray-300">
                {{ $t("menu_t2") }}
              </p>
              <ul class="mt-2 space-y-2">
                <li>
                  <router-link
                    to="/agenda"
                    class="text-gray-500 font-medium transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    {{ $t("menu_t10") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/mediatheque"
                    class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    {{ $t("menu_t3") }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div>
              <p class="font-medium tracking-wide text-gray-300 mt-5 sm:mt-0">
                {{ $t("footer_t4") }}
              </p>
              <ul class="mt-2 space-y-2">
                <li>
                  <router-link
                    to="/langues"
                    class="text-gray-500 font-medium transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    {{ $t("menu_t4") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/certifications"
                    class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                  >
                    {{ $t("menu_t12") }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/campusfrance"
                    class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                    >Campus France</router-link
                  >
                </li>
              </ul>
            </div>
            <div>
              <p class="font-medium tracking-wide text-gray-300 mt-5 sm:mt-0">
                {{ $t("footer_t5") }}
              </p>
              <ul class="mt-2 space-y-2">
                <li>
                  <a
                    href="tel:+243841193475"
                    class="text-gray-500 font-medium transition-colors duration-300 hover:text-deep-purple-accent-200"
                    >(+243)841 193 475</a
                  >
                </li>
                <li class="truncate max-w-[100px] text-gray-500">
                  <a
                    href="email:accueil@institutfrancaisbukavu.com"
                    class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                    >accueil@institutfrancaisbukavu.com</a
                  >
                </li>
                <li>
                  <a
                    href="https://www.google.com/maps/dir/-1.6898833,29.2372971/institut+francais+bukavu/@-2.036256,28.6822305,10z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x19c299ff05f6eed5:0xaccb07dfd54f7a0e!2m2!1d28.8563449!2d-2.5017349?entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-200"
                    >{{ $t("footer_t6") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="md:max-w-md lg:col-span-2 lg:-ml-10 lg:mt-0 mt-10">
            <span class="text-2xl font-bold tracking-wide text-gray-300 sm:text-2xl">
              {{ $t("footer_t7") }}</span
            >
            <p class="mt-4 text-md text-gray-500">
              {{ $t("footer_t8") }}
            </p>
            <div
              class="mt-4 xl:flex xl:justify-start space-y-4 xl:space-y-0 xl:space-x-4"
            >
              <a
                href="https://chat.whatsapp.com/HxzgUnqWTdZ2VxAO8XGK1p"
                target="_blank"
                class="xl:w-1/2 flex justify-start rounded-md group text-white bg-green-500 py-2 px-4"
              >
                <div class="w-8 h-8 my-auto">
                  <img src="/img/icons/socials/whatsapp.png" alt="" class="w-full" />
                </div>
                <div class="my-auto ml-4">
                  <span class="text-xs opacity-90">{{ $t("footer_t9") }}</span> <br />
                  <span class="font-medium group-hover:underline">Whatsapp</span>
                </div>
                <div class="py-4 ml-auto">
                  <svg
                    class="w-4 h-4 opacity-70 ml-5 my-auto"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    data-v-d3eaab7e=""
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      data-v-d3eaab7e=""
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://fr-fr.facebook.com/IFBukavu/"
                target="_blank"
                class="xl:w-1/2 flex justify-start rounded-md group text-white bg-blue py-2 px-4"
              >
                <div class="w-8 h-8 my-auto">
                  <img src="/img/icons/socials/facebook.png" alt="" class="w-full" />
                </div>
                <div class="my-auto ml-4">
                  <span class="text-xs opacity-90">{{ $t("footer_t10") }}</span> <br />
                  <span class="font-medium group-hover:underline">Facebook</span>
                </div>
                <div class="py-4 ml-auto">
                  <svg
                    class="w-4 h-4 ml-5 opacity-70 my-auto"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    data-v-d3eaab7e=""
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                      data-v-d3eaab7e=""
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
            <form class="flex-col mt-4 md:flex-row hidden">
              <input
                placeholder="Email"
                required=""
                type="text"
                class="flex-grow w-full h-10 px-4 mb-3 transition duration-200 bg-white border border-gray-300 shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              />
              <button
                type="submit"
                class="ifb-button inline-flex items-center justify-center h-10 px-6 font-medium tracking-wide text-white transition duration-200 shadow-md bg-blackgray focus:shadow-outline focus:outline-none"
              >
                S'inscrire
              </button>
            </form>
          </div>
        </div>
        <div
          class="flex flex-col justify-between pt-5 pb-10 border-t border-gray-800 sm:flex-row"
        >
          <p class="text-xs text-gray-500 block sm:flex sm:justify-center">
            <span class="mr-2"
              >{{ $t("footer_t11") }}
              <span>{{ $t("footer_t12") }}</span>
            </span>
            <span class="font-bold text-xs underline"
              ><a
                href="https://www.vincentasani.me/"
                target="_blank"
                class="sm:ml-0 mt-2"
                style="font-size: 11px !important"
                >Vincent Asani</a
              ></span
            >
          </p>
          <div class="flex items-center mt-4 space-x-4 sm:mt-0">
            <a
              href="https://www.youtube.com/channel/UCk_tSSiUm19dEPre5BmBwlA"
              target="_blank"
              class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                class="h-6"
                viewBox="0 0 96.875 96.875"
                xml:space="preserve"
                fill="currentColor"
              >
                <g>
                  <path
                    d="M95.201,25.538c-1.186-5.152-5.4-8.953-10.473-9.52c-12.013-1.341-24.172-1.348-36.275-1.341   c-12.105-0.007-24.266,0-36.279,1.341c-5.07,0.567-9.281,4.368-10.467,9.52C0.019,32.875,0,40.884,0,48.438   C0,55.992,0,64,1.688,71.336c1.184,5.151,5.396,8.952,10.469,9.52c12.012,1.342,24.172,1.349,36.277,1.342   c12.107,0.007,24.264,0,36.275-1.342c5.07-0.567,9.285-4.368,10.471-9.52c1.689-7.337,1.695-15.345,1.695-22.898   C96.875,40.884,96.889,32.875,95.201,25.538z M35.936,63.474c0-10.716,0-21.32,0-32.037c10.267,5.357,20.466,10.678,30.798,16.068   C56.434,52.847,46.23,58.136,35.936,63.474z"
                  />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </a>
            <a
              href="https://twitter.com/if_bukavu"
              target="_blank"
              class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" class="h-5">
                <path
                  d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/if_bukavu/"
              target="_blank"
              class="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 30 30" fill="currentColor" class="h-6">
                <circle cx="15" cy="15" r="4"></circle>
                <path
                  d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"
                ></path>
              </svg>
            </a>
            <a
              href="https://web.facebook.com/IFBukavu/?_rdc=1&_rdr"
              target="_blank"
              class="hidden text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" class="h-5">
                <path
                  d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import store from "@/store/store";
async function openModalVid() {
  store.state.modalState2 = true;
}
export default {
  // eslint-disable-next-line
  name: "Footer",
  setup() {
    return {
      openModalVid,
    };
  },
};
</script>
<style scoped>
li a:hover {
  text-decoration: underline !important;
}
</style>
