/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.log("L'application est servie à partir du cache par un service worker.\n" + "For more details, visit https://goo.gl/AFskqB");
		},
		registered() {
			console.log("Le service worker a été enregistré.");
		},
		cached() {
			console.log("Le contenu a été mis en cache pour une utilisation hors connexion.");
		},
		updatefound() {
			console.log("Un nouveau contenu est en cours de téléchargement.");
		},
		updated(registration) {
			console.log("Un nouveau contenu est disponible ; Rafraichissez, s'il vous plait.");
			window.location.reload(true);
			document.dispatchEvent(new CustomEvent("swUpdated", { detail: registration }));
		},
		offline() {
			console.log("Aucune connexion Internet trouvée. L'application s'exécute en mode hors ligne.");
		},
		error(error) {
			console.error("Erreur lors de l'inscription du service worker:", error);
		}
	});
}
self.addEventListener("message", (event) => {
	if (event.data && event.data.type === "SKIP_WAITING") {
		self.skipWaiting();
	}
});
