// eslint-disable-next-line
import "vue-social-chat/dist/style.css";

import sw from "./translations/sw.json";
import fr from "./translations/fr.json";
import en from "./translations/en.json";

import { createI18n } from "vue-i18n";

const i18n = createI18n({
	messages: {
		sw: sw,
		fr: fr,
		en: en
	},
	allowComposition: true,
	legacy: false,
	locale: "fr",
	fallbackLocale: "fr"
});

import VueSocialSharing from "vue-social-sharing";
import { createApp } from "vue";
import axios from "axios";
// import VueAxios from "vue-axios";
import App from "./App.vue";
import store from "@/store/store";

import VueWriter from "vue-writer";
// import "./assets/styles/main.css";
import router from "./router";
import Vue3Progress from "vue3-progress";
import { VueClipboard } from "@soerenmartius/vue3-clipboard";
import Vue3Transitions from "vue3-transitions";
import ProgCard from "@/components/ProgCard";
import VideoPlayer from "@/components/VideoPlayer.vue";

import { Popover, PopoverButton, PopoverGroup, PopoverPanel, TabGroup, TabList, Tab, TabPanels, TabPanel, TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";

import TranslateC from "@/components/Translate_C.vue";

const options = {
	position: "fixed",
	height: "4px"
};

//Use VueMeta
import { createMetaManager } from "vue-meta";

import "./registerServiceWorker";

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store).use(VueClipboard);
// app.use(VueAxios);
app.use(createMetaManager());
app.use(VueSocialSharing);
app.use(Vue3Transitions);
app.use(VueWriter);
app.component("ProgCard", ProgCard);
app.component("VideoPlayer", VideoPlayer);

// eslint-disable-next-line vue/multi-word-component-names
app.component("Popover", Popover)
	.component("PopoverButton", PopoverButton)
	.component("PopoverGroup", PopoverGroup)
	.component("PopoverPanel", PopoverPanel)
	.component("TabGroup", TabGroup)
	.component("TabList", TabList)
	.component("Tab", Tab)
	.component("TabPanels", TabPanels)
	.component("TabPanel", TabPanel)
	.component("TransitionRoot", TransitionRoot)
	.component("TransitionChild", TransitionChild)
	.component("TranslateC", TranslateC);

// eslint-disable-next-line vue/multi-word-component-names
app.component("Dialog", Dialog);
app.component("DialogOverlay", DialogOverlay);
app.component("DialogTitle", DialogTitle);

app.use(Vue3Progress, options);

app.config.globalProperties.$axios = axios;
app.config.productionTip = true;
app.config.globalProperties.$ressAPIimg = "https://admin.institutfrancaisbukavu.com/";
app.config.globalProperties.$hostname = app.config.productionTip ? "https://institutfrancaisbukavu.com" : "http://localhost:8080";

app.mount("#app");
